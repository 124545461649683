import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import SectionTitle from './SectionTitle'

import './Technology.scss'

const Technology = () => (
  <section className="Technology" id="tech">
    <Container>
      <SectionTitle
        title="Technologie"
        subTitle="Die Technologie von morgen, schon heute: Ob Satellit, Kabel oder
          Terrestrisch, wir sorgen für kristallklaren Empfang. Unsere
          Leistungen bieten wir für jede Art von digitaler Empfangstechnik an."
      />
      <Row>
        <Col sm="4">
          <img src="/img/logo/dvb/DVB-S2.png" alt="dvb_s" />
          <p>
            Digital Video Broadcasting – <strong>S</strong>atellite
          </p>
        </Col>
        <Col sm="4">
          <img src="/img/logo/dvb/DVB-C2.png" alt="dvb_c" />
          <p>
            Digital Video Broadcasting – <strong>C</strong>able
          </p>
        </Col>
        <Col sm="4">
          <img src="/img/logo/dvb/DVB-T2.png" alt="dvb_t" />
          <p>
            Digital Video Broadcasting – <strong>T</strong>errestrial
          </p>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Technology
