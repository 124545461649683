import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import SectionTitle from './SectionTitle'
import MarketingVideo from './MarketingVideo'

import './About.scss'

const About = () => (
  <section className="About" id="about">
    <Container>
      <SectionTitle
        title="Über Uns"
        subTitle="„Wer immer nur das tut, was er schon kann, wird immer nur das sein,
          was er schon ist.”"
      />
      <Row>
        <Col md="6">
          <div className="videoWrapper">
            <MarketingVideo />
          </div>
        </Col>
        <Col md="6">
          <div className="about-text">
            <h3>Wer wir sind</h3>
            <p>
              Wir sind ein etabliertes Unternehmen in der Technikbranche und
              blicken zurück auf mehr als 20 Jahre Berufserfahrung.
            </p>
            <h3>Was wir tun</h3>
            <p>
              Unsere Leistungen sind spezialisiert auf die Planung, Herstellung,
              Installation und Wartung von Antennenanlagen in Wohnobjekten,
              Privathaushalten und Hotellerie.
            </p>
            <h3>Was uns herausragend macht</h3>
            <p>
              Die Fähigkeit, Veränderungen als etwas Positives zu sehen und sie
              konstruktiv zu nutzen, ist eine echte Erfolgseigenschaft. Die Kipa
              Nachrichtentechnik hat das Ziel durch Kundenzufriedenheit und
              Partnerschaften einen erfolgreichen Weg in die Zukunft zu gehen.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default About
