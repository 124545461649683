import React from 'react'
import { Col, Card } from 'reactstrap'
import { Image, Transformation } from 'cloudinary-react'

import FallbackAvatar from '../../svg/FallbackAvatar.svg'

import removeWhiteSpace from '../../utils/removeWhiteSpace'

import { Employee } from '../../hooks/cms/useEmployees'

import styles from './TeamMember.module.scss'
const TeamMember = ({ employee }: Props) => {
  const { avatar, name, role, email, phone } = employee

  return (
    <Col md="4">
      <Card className={styles.wrapper}>
        {avatar ? (
          <Image
            publicId={avatar}
            secure="true"
            className={`${styles.avatar} rounded-circle img-fluid`}
          >
            <Transformation
              width="300"
              height="300"
              crop="fill"
              dpr="auto"
              fetchFormat="auto"
              quality="auto"
            />
          </Image>
        ) : (
          <FallbackAvatar className={styles.avatar} />
        )}
        <div>
          <h3>{name}</h3>
          <p>{role}</p>
        </div>
        {(email || phone) && (
          <p className={styles.contact}>
            {email && (
              <>
                <a href={`mailto:${email}`}>{email}</a>
                <br />
              </>
            )}
            {phone && <a href={`tel:${removeWhiteSpace(phone)}`}>{phone}</a>}
          </p>
        )}
      </Card>
    </Col>
  )
}

export default TeamMember

interface Props {
  employee: Employee
}
