import React from 'react'

const MarketingVideo = () => (
  <iframe
    width="560"
    height="315"
    data-consent="analytics"
    data-src="https://www.youtube-nocookie.com/embed/4OMcIrqoISE?controls=0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
)

export default MarketingVideo
