import React from 'react'

import './SectionTitle.scss'

const SectionTitle = ({ title, subTitle, className = '' }: Props) => (
  <div className={`SectionTitle ${className}`}>
    <h2>{title}</h2>
    <hr />
    {subTitle && <p>{subTitle}</p>}
  </div>
)

interface Props {
  title: string
  subTitle?: string
  className?: string
}

export default SectionTitle
