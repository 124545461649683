import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import SectionTitle from '../SectionTitle'
import ContactForm from './ContactForm'

import GoogleMaps from './GoogleMaps'
import Phone from '../../svg/Phone.svg'
import Email from '../../svg/Email.svg'
import Location from '../../svg/Location.svg'

import './Contact.scss'

const Contact = () => (
  <section className="Contact" id="contact">
    <Container>
      <SectionTitle
        title="Kontaktieren Sie uns"
        subTitle="Wir bemühen uns um eine schnellstmögliche Antwort."
      />
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <div className="contactMethod">
            <GoogleMaps
              query={'KiPA+Nachrichtentechnik,+Partholl+e.U.'}
              zoomLevel={11}
            />
            <Location />
            <p>
              Bundesstraße 18
              <br />A - 6063 Rum
            </p>
          </div>
          <div className="contactMethod">
            <Email />
            <p>
              <a href="mailto:kipa@chello.at">kipa@chello.at</a>
            </p>
          </div>
          <div className="contactMethod">
            <Phone />
            <p>
              <a href="tel:+43512266154">(+43) 512 266 154</a>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <h3>...oder hinterlassen Sie uns eine Nachricht</h3>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </section>
)

export default Contact
