import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import SectionTitle from '../SectionTitle'

import SimpliTVLogo from './simpliTV_logo.png'
import SkyLogo from './sky_logo.png'

import MagentaLogo from '../../svg/magenta-logo.svg'

import './Partners.scss'

const Partners = () => (
  <section className="Partners" id="partner">
    <Container>
      <SectionTitle
        title="Unsere Partner"
        subTitle='"Zusammenkommen ist ein Anfang, Zusammenbleiben ein Fortschritt, und
          Zusammenarbeiten ist Erfolg."'
      />
      <Row className="partner-logos">
        <Col sm="4">
          <img src={SimpliTVLogo} alt="simpliTV logo" />
        </Col>
        <Col sm="4">
          <div className="svgWrapper">
            <MagentaLogo />
          </div>
        </Col>
        <Col sm="4">
          <img src={SkyLogo} alt="sky logo" />
        </Col>
      </Row>
    </Container>
  </section>
)

export default Partners
