import React from 'react'
import Iframe from 'react-iframe'
import './GoogleMaps.scss'

const GMAPS_API_KEY = 'AIzaSyBW7QNZeJQOdqsDLk18t4cjDmTMsB4HsCc'

const GoogleMaps = (config) => {
  const url =
    'https://www.google.com/maps/embed/v1/place' +
    `?key=${GMAPS_API_KEY}` +
    `&q=${config.query}` +
    `&zoom=${config.zoomLevel}`

  return (
    <div className="GoogleMaps">
      <iframe
        title="Google Maps"
        data-consent="analytics"
        data-src={url}
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      />
    </div>
  )
}

export default GoogleMaps
