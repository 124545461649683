import React from 'react'
import { Container, Row } from 'reactstrap'

import SectionTitle from '../SectionTitle'
import Service from './Service'

import Planning from '../../svg/Planning.svg'
import Consult from '../../svg/Consult.svg'
import Wrench from '../../svg/Wrench.svg'
import Maintenance from '../../svg/Maintenance.svg'

import './Services.scss'

const Services = () => (
  <section className="Services" id="services">
    <Container>
      <SectionTitle
        title="Unsere Leistungen"
        subTitle="Sie benötigen Fachexpertise und Branchenkenntnis für die Realisierung
          Ihres Projekts? Wir liefern den Rund-um-Service von der Planung
          bis zur Wartung."
      />
      <Row>
        <Service
          title="Planung"
          subtitle="Gerne unterstützen wir Sie bei der Konzipierung Ihrer Projekte."
        >
          <Planning />
        </Service>
        <Service
          title="Beratung"
          subtitle="Freundlicher Kundendienst steht bei uns an höchster Stelle."
        >
          <Consult />
        </Service>
        <Service
          title="Installation"
          subtitle="Fachkompetenz bei der Errichtung und Konfiguration von Anlagen
            direkt vor Ort."
        >
          <Wrench />
        </Service>
        <Service
          title="Wartung"
          subtitle="Ihre Anlage macht Schwierigkeiten? Wir gehen der Sache auf den
            Grund."
        >
          <Maintenance />
        </Service>
      </Row>
    </Container>
  </section>
)

export default Services
