import React from 'react'

import Hero from '../components/Hero/Hero'
import Services from '../components/Services/Services'
import Technology from '../components/Technology'
import Partners from '../components/Partners/Partners'
import Team from '../components/Team/Team'
import About from '../components/About'
import Contact from '../components/Contact/Contact'

import styles from './index.module.scss'

const HomePage = () => {
  return (
    <div className={styles.wrapper}>
      <Hero />
      <Services />
      <Technology />
      <Partners />
      <Team />
      <About />
      <Contact />
    </div>
  )
}

export default HomePage
