import React from 'react'
import { Col } from 'reactstrap'

import './Service.scss'

const Service = ({ title, subtitle, children }: Props) => (
  <Col md="3" sm="6" className="Service">
    {children}
    <h4>{title}</h4>
    <p>{subtitle}</p>
  </Col>
)

interface Props {
  title: string
  subtitle: string
  children: React.ReactNode
}

export default Service
