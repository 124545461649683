import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import SectionTitle from '../SectionTitle'
import TeamMember from './TeamMember'

import useEmployees from '../../hooks/cms/useEmployees'

import styles from './Team.module.scss'

const Team = () => {
  const employees = useEmployees()

  return (
    <section className={styles.wrapper} id="team">
      <Container>
        <SectionTitle title="Das Team" />
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <Row style={{ justifyContent: 'space-evenly' }}>
              {employees.map(employee => (
                <TeamMember employee={employee} key={employee.id} />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Team
