import { graphql, useStaticQuery } from 'gatsby'

interface RawEmployee {
  id: string
  frontmatter: {
    title: string
    role: string
    avatar?: string
    email?: string
    phone?: string
    employmentDate: string
    sortNumber?: number
  }
}

export interface Employee {
  id: string
  name: string
  role: string
  avatar?: string
  email?: string
  phone?: string
  employmentDate: string
  sortNumber?: number
}

const formatEmployee = (rawEmployee: RawEmployee) => {
  const {
    id,
    frontmatter: {
      title,
      role,
      avatar,
      email,
      phone,
      employmentDate,
      sortNumber,
    },
  } = rawEmployee

  const employee: Employee = {
    id,
    name: title,
    role,
    avatar,
    email,
    phone,
    employmentDate,
    sortNumber,
  }

  return employee
}

const sortEmployees = (employees: Employee[]) =>
  employees.sort((a, b) => {
    const sortNumberA = a.sortNumber || Infinity
    const sortNumberB = b.sortNumber || Infinity
    const preferredSortNumber = sortNumberA - sortNumberB

    const alphabeticalSortNumber = a.employmentDate.localeCompare(
      b.employmentDate,
    )

    return preferredSortNumber || alphabeticalSortNumber
  })

const useEmployees = () => {
  const { employees: rawEmployees } = useStaticQuery(
    graphql`
      query {
        employees: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "employee" } } }
        ) {
          nodes {
            frontmatter {
              avatar
              email
              phone
              published
              role
              title
              employmentDate
              sortNumber
            }
            id
          }
        }
      }
    `,
  ) as {
    employees: {
      nodes: RawEmployee[]
    }
  }

  const employees = rawEmployees.nodes.map(formatEmployee)
  const sortedEmployees = sortEmployees(employees)

  return sortedEmployees
}

export default useEmployees
