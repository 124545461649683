import React from 'react'
import { Container } from 'reactstrap'

import HeroLogo from '../../svg/kipa_logo_extended.svg'

import './Hero.scss'

const Hero = () => (
  <div className="Jumbotron" id="home">
    <Container>
      <HeroLogo />
      <h1>Ihr Partner für Kommunikationstechnologie &amp; Satellitenanlagen</h1>
    </Container>
  </div>
)

export default Hero
