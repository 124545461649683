import React, { Component } from 'react'
import { Row, Col, Form, FormGroup, Input } from 'reactstrap'

import './ContactForm.scss'

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      success: false,
      error: null
    }
  }

  onSubmit = e => {
    const { name, email, message } = this.state

    fetch('/netlify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', name, email, message })
    })
      .then(() => this.setState(prevState => ({ ...prevState, success: true })))
      .catch(error => this.setState(prevState => ({ ...prevState, error })))

    e.preventDefault()
  }

  onChange = e => {
    e.persist()
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  render() {
    const { name, email, message, success, error } = this.state

    return (
      <>
        <Form
          className="ContactForm"
          onSubmit={this.onSubmit}
          name="contact"
          method="post"
        >
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Vor- und Nachname"
                  required
                  onChange={this.onChange}
                  value={name}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-Mail"
                  required
                  onChange={this.onChange}
                  value={email}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Input
                  type="textarea"
                  rows="4"
                  id="message"
                  name="message"
                  placeholder="Beschreiben Sie Ihr Anliegen"
                  required
                  onChange={this.onChange}
                  value={message}
                />
              </FormGroup>
            </Col>
          </Row>
          <button type="submit" color="info">
            Senden
          </button>
          {success && (
            <div className="success">Vielen Dank für Ihre Nachricht!</div>
          )}
          {error && <div className="error">Fehler: {error}</div>}
        </Form>
      </>
    )
  }
}

export default ContactForm
